import { Accordion } from "react-bootstrap";

const CareersAccordian = () => {
    return (
        <Accordion>
            <Accordion.Item eventKey="0">
                <Accordion.Header>General Laborer</Accordion.Header>
                <Accordion.Body>
                    <p>Entry Level Position</p>
                    <p>Responsibilities:</p>
                    <ul>
                        <li>Shoveling</li>
                        <li>Laying Pipes</li>
                        <li>Gravel Grading</li>
                        <li>Transport Equipment/Materials</li>
                    </ul>
                    <p>Requirements</p>
                    <ul>
                        <li>Stand For Long periods of times</li>
                        <li>Must be able to carry 80lbs</li>
                        <li>Work 12 hour shifts</li>
                    </ul>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header>Heavy Equipment Operator</Accordion.Header>
                <Accordion.Body>
                    <p>Mid Level Position</p>
                    <p>Responsibilities:</p>
                    <ul>
                        <li>Operate Heavy Equipment</li>
                        <li>Maintain Safety and Production</li>
                        <li>Excavate and Backfill</li>
                        <li>Guide Laborers</li>
                    </ul>
                    <p>Requirements</p>
                    <ul>
                        <li>Operate loaders, skidsteers, rollers, compactors, trackhoes, and trucks</li>
                        <li>Be able to train/lead</li>
                        <li>Work 12 hour shifts</li>
                    </ul>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header>Forman</Accordion.Header>
                <Accordion.Body>
                    <p>Management Level Position</p>
                    <p>Responsibilities:</p>
                    <ul>
                        <li>Operate Equipment</li>
                        <li>Guide Laborers and Operators</li>
                    </ul>
                    <p>Requirements</p>
                    <ul>
                        <li>General knowledge and experience with heavy equipment</li>
                        <li>General knowledge and experience with reading plans</li>
                        <li>Run Crews and Jobs Effeciently</li>
                        <li>Be able to train/lead</li>
                        <li>Work 12 hour shifts</li>
                    </ul>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

// General laborer- this position shovels, lays pipe, Grades gravel, bottom of the list type shit. 
// Heavy equipment operator- operates an assortment of different machinery and guides laborers to do their jobs
// Forman- guides and leads a crew operators and laborers 

export default CareersAccordian;