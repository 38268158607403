import { WildWestService } from "./types";

const services:Array<WildWestService> = [
    {
        serviceImage: "/images/desktop/concrete-hdpe-pipe.webp",
        serviceCaption: "",
        serviceTitle: "Concrete HDPE Pipe",
        altTag: "Concrete HDPE Pipe",
    },
    {
        serviceImage: "/images/desktop/driveways-services.webp",
        serviceCaption: "",
        serviceTitle: "Driveways",
        altTag: "Driveways",
    },
    {
        serviceImage: "/images/desktop/foundation-backfill.webp",
        serviceCaption: "",
        serviceTitle: "Foundation Prep & Backfill",
        altTag: "Foundation Prep & Backfill",
    },
    {
        serviceImage: "/images/desktop/residential-services.webp",
        serviceCaption: "",
        serviceTitle: "Residential Services",
        altTag: "Residential Services",
    },
    {
        serviceImage: "/images/desktop/wall.jpg",
        serviceCaption: "",
        serviceTitle: "Stone Cut Steps",
        altTag: "Stone cut steps",
    },
    {
        serviceImage: "/images/desktop/rock-wall.webp",
        serviceCaption: "",
        serviceTitle: "Rock Walls",
        altTag: "Rock Walls",
    },
    {
        serviceImage: "/images/desktop/sewer-water-repair.webp",
        serviceCaption: "",
        serviceTitle: "Sewer and Water Repair",
        altTag: "Sewer and Water Repair",
    },
    {
        serviceImage: "/images/desktop/small-land-development.webp",
        serviceCaption: "",
        serviceTitle: "Small Land Development",
        altTag: "Small Land Development",
    },
];

export default services;