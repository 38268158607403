import emailJs from "@emailjs/browser";

export const sendEmail = async (fullname, email, message) =>  {
    emailJs.init("PcFMZDPVPMIPRLT2N");
    const data = {
        name: fullname,
        email: email,
        message: message
    };
    await emailJs.send("service_sxpl7ad", "template_8tuicis", data);
}