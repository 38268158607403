import { Carousel } from "react-bootstrap";
import { useState } from "react";
import services from "../utils/wildWestServices";
import { v4 as uuidv4 } from "uuid";
import { WildWestService } from "../utils/types";

const ServicesCarousel = () => {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex: number) => {
        setIndex(selectedIndex);
    };

    return (
        <Carousel activeIndex={index} onSelect={handleSelect}>
            {
                services.map((service:WildWestService) => {
                    return (
                        <Carousel.Item key={uuidv4()} style={{height: "60vh"}}>
                            <div className="carousel-overlay" />
                            <img
                                className="d-block w-100 carousel-image"
                                src={service.serviceImage}
                                alt={service.altTag}
                            />
                            <Carousel.Caption>
                                <h3>{service.serviceTitle}</h3>
                                <p>{service.serviceCaption}</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                    )
                })
            }
        </Carousel>
    );
}

export default ServicesCarousel;