import { useState } from "react";
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import { sendEmail as emailJsSend} from "../utils/emailJsApi";

const ContactForm = ()  => {    
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [errors, setErrors] = useState({
        nameError: false,
        emailError: false,
        messageError: false,
    });
    const [isSending, setIsSending] = useState(false);
    const [isSent, setIsSent] = useState(false);

    const sendEmail = (event) => {
        event.preventDefault();
        setIsSending(true);
        const tempErrors = {
            nameError: false,
            emailError: false,
            messageError: false,
        }
        const emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if(!name || !email || !message) {
            tempErrors.nameError = name === "";
            tempErrors.emailError = email === "";
            tempErrors.messageError = message === "";
            setErrors((_) => tempErrors);
            setIsSending(false);
            return;
        }
        else if(!emailPattern.test(email)) {
            tempErrors.emailError = true;
            setErrors((_) => tempErrors);
            setIsSending(false);
            return;
        }

        setErrors((_) => tempErrors);
        try {
            emailJsSend(name, email, message);
            setIsSent(true);
            setIsSending(false);   
        } catch (e) {
            setIsSent(false);
            setIsSending(false);   

        } 
    }

    return (
        <Container fluid className="contact-form-container">
            <Form onSubmit={sendEmail}>
                <Row>
                    <Form.Group 
                        as={Col} 
                        xs={12} 
                        md={6} 
                        controlId="name-field"
                    >
                        <Form.Label>Full Name</Form.Label>
                        <Form.Control 
                            type="text" 
                            placeholder="Name" 
                            aria-placeholder="Name"
                            value={name}
                            onChange={({target}) => setName(target.value)}
                            isInvalid={errors.nameError}
                        />
                    </Form.Group>
                    <Form.Group 
                        as={Col} 
                        xs={12} 
                        md={6} 
                        controlId="email-field"
                    >
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            placeholder="Email" 
                            aria-placeholder="Email"
                            value={email}
                            onChange={({target}) => setEmail(target.value)}
                            isInvalid={errors.emailError}
                        />
                    </Form.Group>
                    
                </Row>
                <Row>
                    <Form.Group 
                        as={Col} 
                        controlId="message-field"
                    >
                        <Form.Label>Message</Form.Label>
                        <Form.Control 
                            as="textarea" 
                            type="email" 
                            placeholder="Message" 
                            aria-placeholder="Message"
                            rows={4}
                            value={message}
                            onChange={({target}) => setMessage(target.value)}
                            isInvalid={errors.messageError}
                        />
                    </Form.Group>
                </Row>
                <Row>
                    <Col xs={12} sm={{offset: 2, span: 8}} className="text-center" >
                        <Button 
                            type="submit" 
                            style={{width: "80%"}} 
                            className="contact-form-button"
                            disabled={isSent || isSending}
                        >
                            { isSending ? "Sending" : isSent ? "Sent" : "Send"}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Container>
    )
}

export default ContactForm;