import { BrowserRouter as Router, Routes, Route, } from "react-router-dom";
import Layout from "./components/Layout";
import HomePage from "./pages/HomePage";


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
        </Route>
        <Route path="*" element={<h1>Not Found :( 404</h1>} />
      </Routes>
    </Router>
  );
}

export default App;
