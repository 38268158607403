import { Card, Col, Container, Row } from "react-bootstrap";
import CareersAccordian from "../components/CareersAccordian";
import ServicesCarousel from "../components/ServicesCarousel";
import TeamMemberItem from "../components/TeamMemberItem";
import { Helmet } from "react-helmet";

const HomePage = () => {
    return (
        <>
            <Helmet>
                <title>Wild West Excavation | Home</title>
                <meta
                    name="description"
                    content="Professional excavtion brought to you by shayla and weston cropper"
                />
                <meta
                    name="keywords"
                    content="wild west excavation, excavation, shayla, weston, small land developement, development, hiring, foundation, services, gravity wall, rock wall"
                />
            </Helmet>
            <Container 
                fluid 
                className="header-section"
            >
                <picture>
                    <img className="header-image" src="/images/mobile/valenzuela-wall.webp" alt="Valenzuela Walls project serviced by Wild West Excavating" />
                </picture>
                <div className="header-overlay"/>
            </Container>
            <section className="container about-section" id="about">
                <h2 className="text-center about-header">Professional Excavating Services</h2>
                <p className="about-paragraph">
                    Wild West excavating is a small, veteran owned company. We provide the highest quality of work at very 
                    competitive pricing. We have been moving dirt for many years, and have gained a great amount of knowledge 
                    and experience along the way. We have worked in many different excavation specialties, from the Bakken 
                    oilfield in North Dakota, to solar panels and utilities in our home state of Utah.
                </p>
                <p className="about-subtext">
                    Our main goal is to be able to leave a job site with a satisfied feeling, and a happy customer. We offer a 
                    very wide range of services and chances are, we can accomplish whatever you envision. From grading, to 
                    utilities, home foundations, rock and block walls, and so much more. <a href="#contact" title="contact-us">Contact us</a> today to see if we can assist 
                    you in your endeavors.
                </p>
            </section>
            <section 
                className="container-fluid team-section pb-5" 
                style={{ background: "url(/images/red-dirt.webp)"}}
                id="team"
            >
                {/* Bosses Section and Header */}
                <Row>
                    <Col 
                        xs={{span: 12, order: 2}} 
                        sm={{offset: 1, span: 5, order: 2}}
                        md={{span: 4, order: 1}}
                        lg={{offset: 2,span: 3}}
                        className="mt-5 plr-1"
                    >
                        <TeamMemberItem 
                            imageAlt="Shayla posing with a fish" 
                            imageSrc="/images/desktop/shayla.webp" 
                            teamMemberName="Shayla Cropper"
                            teamMemberTitle="Owner/Operator's Boss"
                        />
                    </Col>
                    <Col 
                        xs={{offset: 2, span: 8, order: 1}}
                        sm={{offset: 4, span: 5, order: 1}}
                        md={{offset: 0, span: 2, order: 2}}
                        lg={{span: 2}}
                        className="align-self-center"
                    >
                        <Card bg="light" text="dark" className="mt-4">
                            <Card.Body>
                                <Card.Title className="text-center">The Team</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col 
                        xs={{span: 12, order: 3}} 
                        sm={{span: 5, order: 3}}
                        md={{span: 4, order: 3}}
                        lg={{span: 3}}
                        className="team-member-container mt-5 plr-1"
                    >
                        <TeamMemberItem 
                            imageAlt="Weston Popsing With A Tool" 
                            imageSrc="/images/desktop/weston.webp" 
                            teamMemberName="Weston Cropper" 
                            teamMemberTitle="Owner/Operator"
                        />
                    </Col>
                </Row>
            </section>

            <section className="container services-section" id="services">
                <h2 className="text-center services-header">Wild West Services</h2>
                <ServicesCarousel />
            </section>
            <section className="container careers-section" id="careers">
                <Row className="pb-5">
                    <Col className="align-self-center" xs={12} lg={6}>   
                        <h2 className="text-center careets-header">We're Hiring!</h2>
                    </Col>
                    <Col xs={12} lg={6}>
                        <CareersAccordian />
                        <p className="pt-3">Intersted in joining the team?</p>
                        <p> Send a copy of your resume to us at <a href="mailto:weston@wildwestex.com" title="email to wild west services">weston@wildwestex.com</a> with a description of what job you are applying for.</p>   
                    </Col>
                </Row>
            </section>
        </>
    );
}

export default HomePage; 