import { Card, CardImg } from "react-bootstrap";

interface Props {
    imageSrc: string,
    imageAlt: string,
    teamMemberName: string,
    teamMemberTitle: string,
}

const TeamMemberItem = ({ imageSrc, imageAlt, teamMemberName, teamMemberTitle }:Props) => {
    return (
        <Card className="team-member-container text-center">
            <CardImg className="team-member-image" variant="top" src={imageSrc} alt={imageAlt} />
            <Card.Body>
                <Card.Title>{teamMemberName}</Card.Title>
                <Card.Text>
                    {teamMemberTitle}
                </Card.Text>
            </Card.Body>
        </Card>
    );
}

export default TeamMemberItem;