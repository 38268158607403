import { Outlet } from "react-router-dom";
import { Container, Image, Nav, Navbar, Col, Row } from "react-bootstrap";
import { BsTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { FaMapMarkerAlt, FaFacebookSquare } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import ContactForm from "../components/ContactForm";

const Layout = () => {
    return (
        <>
            <header>
                <Navbar className="wild-west-brown-bg" expand="md">
                    <Container>
                        <Navbar.Brand href="/" className="navbar-header">
                            <Image className="header-icon" src="/images/icon-vector.webp" alt="wild west icon" />
                            Wild West Excavating
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="wild-west-nav"/>
                        <Navbar.Collapse className="justify-content-end" id="wild-west-nav">
                            <Nav.Link href="#about">About</Nav.Link>
                            <Nav.Link href="#team">The Team</Nav.Link>
                            <Nav.Link href="#services">Our Work</Nav.Link>
                            <Nav.Link href="#careers">Careers</Nav.Link>
                            <Nav.Link href="#contact">Contact</Nav.Link>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header>
            <main>
                <Outlet />
            </main>
            <footer className="container-fluid" id="contact">
                <h2 className="text-center footer-header">Contact Us</h2>
                <Container>
                    <Row>
                        <Col xs={12} md={7} className="pb-5 contact-form-section">
                            <ContactForm />
                        </Col>
                        <Col xs={12} md={5} className="pb-3 pl-5 pr-5 contact-info-section d-flex">
                            <div className="flex-container">
                                <h4>Hours of operation:</h4>
                                <p>Mon-Fri: 7 a.m. - 5 p.m. MST</p>
                                <p className="pb-3">Sat-Sun: Closed</p>
                                <h4>Contact Information:</h4>
                                <p>
                                    <BsTelephoneFill /> <a href="tel:435-896-7893" title="wild west phone number">435-896-7893</a>
                                </p>
                                <p className="pb-4">
                                    <MdEmail /> <a href="mailto:weston@wildwestex.com" title="wild west email">weston@wildwestex.com</a>
                                </p>
                                <p className="pb-4">
                                    <FaMapMarkerAlt /> <a target="_blank" rel="noreferrer" href="https://www.google.com/maps/place/1415+S+Hoytsville+Rd,+Coalville,+UT+84017/@40.8514916,-111.3849461,17z/data=!3m1!4b1!4m5!3m4!1s0x87523e0ae041c5b9:0x978dd7b4523bc339!8m2!3d40.8514916!4d-111.3827574" title="wild west address">1415 S Hoytsville Rd, Coalville, UT, 84017</a>
                                </p>
                            </div>
                            <div className="flex-container">
                                <Row className="text-center pb-3">
                                    <Col xs={6} lg={3}>
                                        <a href="https://www.facebook.com/wildwestex" title="wild west facebook link" target="_blank" rel="noopener noreferrer">
                                            <FaFacebookSquare size={32} />
                                        </a>
                                    </Col>
                                    <Col xs={6} lg={3}>
                                        <a href="https://www.instagram.com/wildwestexcavating/" title="wild west instagram link" target="_blank" rel="noopener noreferrer">
                                            <AiFillInstagram size={32} />
                                        </a>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    );
}

export default Layout;